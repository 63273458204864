
import { Http } from "@/http"
import { Id, res_list, req_list } from '@/types/global'


export interface address{
    province:string
    city:string
    region:string
    phone:number,
    username:string,
    remark:string
}

export interface order{
    status:number
    price:string
    remark:string
    order_no:string
    create_time:string
    pay_time:string
    boxs:string
}

export interface user{
    id:Id
    nickname:string
    phone:number
    user_time:number
    create_time:string,
    user_type:string
}
export interface goods{
    id:Id
    name:string
    price:number
    cover:string,
    number:string
}
export interface store{
    id:Id,
    name:string,
}
export interface orderDetail{
    id:Id
    address:address
    goods:goods
    user:user
    store:store
    order:order
    product_name:string,
    step1:string
    step2:string
    step3:string
    price:string
    boxs:string
    put_user:string
    get_user:string
}
export interface orderDetailItem{
    id:Id,
    phone:string,
    number:string,
    barcode:string,
    order_no:string,
    clear_code:string
    remark:string
    store:string
    status:number
    product_name: string
}
export interface putItem{
    id:Id,
    clear_code:string,
    step2:string,
    status:number,
    put_time:string,
}
export interface outItem{
    id:Id,
    clear_code:string,
    // step3:string,
    status:number,
    get_time:string,
}
export interface approach{
    id:Id,
    status:number,
}
class Httporder extends Http{
	get_list = (data:req_list) => {
		return this.get<res_list<orderDetail>>({...data})
	}

    get_info = (id:number) => {
        return this.get<orderDetail>({},`/admin/order/${id}/`)
    }


    edit = ({id,...data}: orderDetail) => {
        return this.patch(data,`/admin/order/${id}/`)
    }
    approach = ({id,...data}: approach) => {
        return this.patch(data,`/admin/order/${id}/`)
    }
    quality = ({id,...data}: approach) => {
        return this.patch(data,`/admin/order/${id}/`)
    }

    del_one = (id:number) => {
        return this.delete(id)
    }
    get_item = (data:any) => {
        return this.post<orderDetailItem>(data,`/admin/order_detail/`)
    }
    put_in =({id,...data}: putItem) => {
        return this.patch(data,`/admin/order/${id}/`)
    }
    out_in =({id,...data}: outItem) => {
        return this.patch(data,`/admin/order/${id}/`)
    }
}

export const api_order =  new Httporder('/admin/order/')
